import { scrollToSection } from './scroll'

const burger = document.querySelector('.hamburger'),
  header = document.querySelector('header'),
  menu = Array.from(document.querySelectorAll('.mobile-menu')),
  links = document.querySelectorAll(
    '.navigation__link, .header__socicons .socicon a',
  )

burger?.addEventListener('click', () => {
  burger.classList.contains('is-active') ? close() : open()
})

links?.forEach((link) => {
  link.addEventListener('click', (event) => {
    const reg = /#\w+/i

    if (reg.test(link.href)) {
      event.preventDefault()
      scrollToSection(document.querySelector(reg.exec(link.href)))
    }
    window.innerWidth < 768 && close()
  })
})

header
  .querySelectorAll('a')
  .forEach((link) => link.addEventListener('click', close))

function open() {
  burger?.classList.add('is-active')
  header?.classList.add('is-active')
  menu.forEach((el) => el?.classList.add('is-active'))
  document.body.classList.add('no-scroll')

  setTimeout(() => {
    menu.forEach((el) => (el.style.opacity = 1))
  })

  // window.addEventListener('wheel', preventScroll, { passive: false })
  // window.addEventListener('touchmove', preventScroll, { passive: false })
  // window.addEventListener('scroll', close)
}
function close() {
  menu.forEach((el) => (el.style.opacity = 0))
  burger?.classList.remove('is-active')
  header?.classList.remove('is-active')
  document.body.classList.remove('no-scroll')

  setTimeout(() => {
    menu.forEach((el) => el?.classList.remove('is-active'))
  }, 500)

  window.removeEventListener('wheel', preventScroll)
  window.removeEventListener('touchmove', preventScroll)
}

function preventScroll(event) {
  event.preventDefault()
}
