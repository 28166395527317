import Player from '@vimeo/player'

const promoVideo = document.querySelector('.billboard iframe')

const playerPromo = new Player(promoVideo)

window.addEventListener('load', () => playerPromo.play())

//
// const heroVideoHandler = (player, popupPlayer, popup, btn) => {
//     let isFirstDisplay = true
//
//     btn.addEventListener('click', (evt) => {
//         evt.preventDefault()
//
//         popup.classList.add('popup-open')
//
//         if (!isFirstDisplay) {
//             isFirstDisplay = false
//         }
//
//         popupPlayer.play()
//
//     })
//
//     const options = {
//         attributes: true
//     }
//
//     // todo: логика скролла
//     const callback = (mutationList) => {
//         mutationList.forEach((mutation) => {
//             if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
//                 playerPromo.play()
//             }
//         })
//     }
//
//     const observer = new MutationObserver(callback)
//
//     observer.observe(popup, options)
//
//     playerPromo.ready()
//         .then(() => {
//             playerPromo.on('play', () => {
//                 window.landingHandler()
//             })
//         })
// }
//
