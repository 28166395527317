import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper'

const reviewsSlider = document.querySelector('.reviews__swiper')
const sliderNext = document.querySelector('.reviews__swiper .slider-btn--next')
const sliderPrev = document.querySelector('.reviews__swiper .slider-btn--prev')
const sliderPagination = document.querySelector(
  '.reviews__swiper .reviews__pagination',
)
const sliderScrollbar = document.querySelector(
  '.reviews__swiper .reviews__scrollbar',
)

reviewsSlider &&
  new Swiper(reviewsSlider, {
    modules: [Navigation, Pagination, Scrollbar],
    slidesPerView: 'auto',
    centeredSlides: true,
    // loop: true,
    pagination: {
      el: sliderPagination,
      clickable: true,
    },
    navigation: {
      nextEl: sliderNext,
      prevEl: sliderPrev,
    },
    scrollbar: {
      el: sliderScrollbar,
    },
    breakpoints: {
      999: {
        centeredSlides: false,
      },
    },
  })
