import { ClickScrollPlugin, OverlayScrollbars } from 'overlayscrollbars'

OverlayScrollbars.plugin(ClickScrollPlugin)

const mobile = document.querySelector('.partners__container--mobile')

const logoWrapper = mobile.querySelector('.partners__wrapper--logo')
const ambassadorsWrapper = mobile.querySelector(
  '.partners__wrapper--ambassadors',
)

const partnersInitScroll = (wrapper) => {
  const parntersImg = wrapper.querySelector('.partners__names--img')
  const parntersName = wrapper.querySelector('.partners__names--title')

  const scrollbarImg = OverlayScrollbars(parntersName, {
    paddingAbsolute: true,
    overflow: {
      y: 'hidden',
    },
    scrollbars: {
      pointers: ['mouse', 'touch', 'pen'],
      clickScroll: true,
    },
  })

  const scrollbarTitle = OverlayScrollbars(parntersImg, {
    paddingAbsolute: true,
    overflow: {
      y: 'hidden',
    },
    scrollbars: {
      pointers: ['mouse', 'touch', 'pen'],
      clickScroll: true,
      visibility: 'hidden',
    },
  })

  const viewportImg = scrollbarImg.elements().viewport
  const viewportTitle = scrollbarTitle.elements().viewport

  const scrollToObserver = () => {
    const scrollLeft = viewportImg.scrollLeft
    viewportTitle.scrollTo({ left: scrollLeft })
  }

  viewportImg.addEventListener('scroll', scrollToObserver)
}

partnersInitScroll(logoWrapper)
partnersInitScroll(ambassadorsWrapper)
