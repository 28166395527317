const wrapper = document.querySelector('.faq')
const faqBtns = document.querySelectorAll('.faq__item-inner')

const contentToggle = (evt) => {
  evt.preventDefault()

  const target = evt.currentTarget.parentNode

  const activeTarget = wrapper.querySelector('.faq__item--active')
  if (activeTarget && !target.classList.contains('faq__item--active')) {
    activeTarget.classList.remove('faq__item--active')
    const activeText = activeTarget.querySelector('.faq__answer')
    activeText.style.maxHeight = null
  }

  const text = target.querySelector('.faq__answer')
  if (target.classList.contains('faq__item--active')) {
    target.classList.remove('faq__item--active')
    text.style.maxHeight = null
  } else {
    target.classList.add('faq__item--active')
    text.style.maxHeight = text.scrollHeight + 'px'
  }
}

faqBtns.forEach((btn) => {
  btn.addEventListener('click', contentToggle)
})
