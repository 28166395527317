import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()
import { scrollToSection } from './scroll'

const navLink = document.querySelectorAll('.js-scroll')
navLink.forEach((el) => {
  el.addEventListener('click', function (evt) {
    evt.preventDefault()
    let blockToScroll = document.querySelector(`#${el.dataset.id}`)
    scrollToSection(blockToScroll)
  })
})
